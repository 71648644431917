<template>
  <div class="personal-contaner">
    <el-row class="repair">
      <!-- 表单部分 -->
      <el-row class="form-box">
        <el-form
          label-width="72px"
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <el-form-item label="头像预览:" required>
            <el-upload
              :action="objData.host"
              :data="objData"
              multiple
              :limit="3"
              list-type="picture-card"
              :before-upload="beforeUploadFun"
              :on-progress="progressFun"
              :on-remove="handleRemove"
              :on-success="videoSuccess"
              :on-change="handleChange"
              :file-list="fileList"
            >
              <div slot="trigger" class="add-upload-box" v-if="showAddSource">
                <img class="img" :src="imgSrc" alt="" />
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="ID:" required prop="id">
            <el-input
              type="number"
              maxlength="6"
              v-model="ruleForm.id"
              placeholder="623547"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>

          <el-form-item label="昵称:" required prop="name">
            <el-input
              type="text"
              v-model="ruleForm.name"
              placeholder="张喀"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="性别:" required prop="sex">
            <div class="item" @click="changeSex('男')">
              <img
                v-if="ruleForm.sex == '男'"
                src="@/assets/images/home/correct.png"
                alt=""
              />
              <img
                src="@/assets/images/home/no-correct.png"
                v-if="ruleForm.sex != '男'"
                alt=""
              />
              <span>男</span>
            </div>
            <div class="item item2" @click="changeSex('女')">
              <img
                v-if="ruleForm.sex == '女'"
                src="@/assets/images/home/correct.png"
                alt=""
              />
              <img
                src="@/assets/images/home/no-correct.png"
                v-if="ruleForm.sex != '女'"
                alt=""
              />
              <span>女</span>
            </div>
          </el-form-item>

          <el-form-item label="生日:">
            <!-- <el-input
              type="date"
              v-model="ruleForm.date"
              placeholder="9-12-06"
              autocomplete="off"
            ></el-input> -->
            <el-date-picker
              v-model="value2"
              align="right"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button class="login" @click="submitForm('ruleForm')"
              >编辑修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangPersonal",

  data() {
    var validateName = (rule, value, callback) => {
      console.log(/^1[3-9]\d{9}$/.test(this.ruleForm.phone));
      if (value === "") {
        callback(new Error("请输入昵称"));
      }
      callback();
    };
    var validatePone = (rule, value, callback) => {
      console.log(/^1[3-9]\d{9}$/.test(this.ruleForm.phone));
      if (value === "") {
        callback(new Error("请输入id"));
      }
      callback();
    };
    var validateDate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入日期"));
      }
      callback();
    };
    return {
      value2: "",
      pickerOptions: {
        disabledDate(time) {
          // console.log(time.getTime(), "time");
          // console.log(Date.now(), "88");
          return time.getTime() > Date.now();
        },
      },
      imgSrc: "", //图片地址
      ruleForm: {
        name: "",
        id: "",
        sex: "男",
        date: "",
      },
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        id: [{ validator: validatePone, trigger: "blur" }],
        date: [{ validator: validateDate, trigger: "blur" }],
      },

      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏

      companyId: "",
    };
  },

  created() {
    // 设置个人信息
    this.setInfo();
  },

  methods: {
    // 图片上传方法
    videoSuccess(res, file, fileList) {
      // this.findvideocover(file.url, file);
    },

    // 图片上传前函数
    beforeUploadFun(file) {
      console.log("上传前函数", file);
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      if (this.upImg.length) {
        // 用户已经不是第一次上传了  判断一定要是图片才可以
        // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
        var fileType = file.type.split("/")[0];
        // let res = imglist.some((item) => {
        //   return item == fileType;
        // });
        // console.log(res, "结果");
        if (fileType != "image") {
          // 上传的不是图片
          // console.log("结果是图片");
          this.$message.info("请继续上传图片");
          this.fileList.pop();
          return false;
        }
      }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });
            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            if (this.upImg.length == 1) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 设置个人信息
    setInfo() {
      this.imgSrc = this.$store.state.userInfo.avatar ?  this.$store.state.userInfo.avatar : 'https://cloud.tiantianhuoke.com/static/admin/images/user_none.png';
      this.ruleForm.id = this.$store.state.userInfo.id;
      this.ruleForm.name = this.$store.state.userInfo.nickName;
      this.ruleForm.sex = this.$store.state.userInfo.sex == 1 ? "男" : "女";
      this.value2 = this.$store.state.userInfo.birthday.split(" ")[0];
    },
    // 改变性别
    changeSex(sex) {
      this.ruleForm.sex = sex;
    },

    progressFun() {},

    handleRemove() {},

    handleChange() {},
    // 格式化时间
    formatTime(value) {
      let date = new Date(this.value2);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    // 提交
    submitForm(formName) {
      // console.log(this.value2, "ppp");
      let time = this.formatTime(this.value2);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 图片上传参数
          let filelistStr = this.upImg.map((item) => item).join(",");
          if (filelistStr) {
            this.imgSrc = filelistStr;
          }

          // 提交
          let sex = this.ruleForm.sex == "男" ? 1 : 2;
          let paramData = {
            avatar: this.imgSrc, //头像
            nickname: this.ruleForm.name,
            sex,
            birthday: time,
          };
          this.$API
            .editMsg(paramData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                this.$store.dispatch("getUserInfo");
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.personal-contaner {
  .add-upload-box {
    position: relative;
    top: -35px;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 72px !important;
    height: 72px !important;
    border: none !important;
    position: relative !important;
    top: -15px !important;
  }

  .el-icon-delete {
    display: none !important;
  }

  .el-upload-list__item-thumbnail {
    width: 72px !important;
    height: 72px !important;
    border-radius: 50% !important;
    border: none !important;
  }

  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    border: none !important;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
  }

  .add-upload-box {
    img {
      border-radius: 50%;
    }
  }

  width: 100%;
  height: 100%;
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #333333 !important;
  }
  .repair {
    width: 976px;
    height: 766px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    padding: 40px;
    .title {
      font-size: 22px;
      color: #333333;
      line-height: 30px;
      padding-bottom: 16px;
      border-bottom: 1px solid #eeeeee;
    }
    .form-box {
      margin-top: 32px;
      margin-left: 32px;
      .img {
        width: 72px;
        height: 72px;
        vertical-align: middle;
        position: relative;
        top: -20px;
        left: 15px;
      }
    }
  }
  .el-form-item {
    width: 500px;
    height: 40px;
    margin-bottom: 32px;
    .el-input {
      width: 320px;
      height: 40px;
      margin-left: 20px;
      color: #333333;
    }
  }
  .el-form-item__error {
    left: 20px !important;
  }
  .el-form-item__label {
    text-align: right;
  }
  .el-radio-group {
    .el-radio__inner {
      width: 16px;
      height: 16px;
      border: 1px solid red;
      border-radius: 0;
    }
  }
  .is-checked {
  }
  .btn {
    margin-left: 26px;
    margin-top: 100px;
    button {
      width: 72px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #f6f7f9;
      border-radius: 28px 28px 28px 28px;
      border: 1px solid #d6d6d6;
      margin-right: 13px;
      &.active {
        background: #ffe5e7;
        color: #ff0015;
        border: 1px solid #ff0015;
      }
    }
  }
  .login {
    width: 214px;
    height: 50px;
    background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
    border-radius: 8px 8px 8px 8px;
    font-size: 16px;
    color: #ffffff;
    margin-top: 246px;
    margin-left: 336px;
  }
  .el-radio-group {
    margin-left: 20px !important;
  }
  .el-radio__inner::after {
    width: 0px;
    height: 0px;
    // src\assets\images\home\correct.png
    background-image: url("../../assets/images/home/correct.png");
    background-size: 16px 16px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: #ffffff;
  }
  // 性別
  .item {
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  .item2 {
    margin-left: 56px;
  }
}
</style>